const TOKEN_KEY = 'm-client';
const CART_KEY = 'm-cart';

export const IS_DEV = false;

export const COLOR_BOOK_OTHERID = "21";
export const ORDER_STATUS = [
    { id: 1, name: '全て' },
    { id: 2, name: '確認中' },
    { id: 3, name: '手配済' },
    // { id: 3, name: '確認済' },
    // { id: 4, name: '配送済' },
]
export const DELIVERY_TIME_ORDER = {
    "1": '',        //0指定なし
    "2": 'AM',      //2午前便
    "3": 'PM',      //4午後便
};

export const SALESSLIP_STATUS = {
    "1": "納品予定",   //手配中
    "101": "納品予定", //納期確定
    "301": "納品済み", //納品済み
}

export const MESSAGE = {
    INFO: 'info',
    ERROR: 'error',
    WARNING: 'warning',
}

export const DELIVERY_TIME = [
    { id: 1, name: '0指定なし' },
    // { id: 4, name: '1朝一番' },
    { id: 2, name: '2午前便' },
    // { id: 5, name: '3昼一番' },
    { id: 3, name: '4午後便' },
];


export const BASE_LIST = [
    { id: 1, name: '本社' },
    { id: 101, name: '玉津' },
    { id: 105, name: '姫路' }
]


export const DELIVERY_TYPE = [
    // { id: 1, name: '未入力' },
    { id: 2, name: '現場届け' },    //SiteTypeID: 1
    { id: 3, name: '店届け' },      //SiteTypeID: 2
    { id: 4, name: '倉庫届け' },    //SiteTypeID: 3
    { id: 5, name: '引取り' },
]

export const BARGAIN_DELIVERY = [
    "倉庫届け",
    "本社引き取り",
    "玉津支店引き取り",
    "姫路支店引き取り"
]
const Common = {
    localStorageSetToken: (cUserName, cToken, cClientName, cDivision) => {
        const userInfo = {
            cUserName: cUserName,
            cToken: cToken,
            cClientName: cClientName,
            cDivision: cDivision,
        }
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(userInfo));
    },

    localStorageGetToken: () => {
        const emptyInfo = {
            cUserName: '',
            cToken: '',
            cClientName: '',
        }
        const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || { ...emptyInfo };

        return userInfo;
    },

    localStorageRemoveToken: () => {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(CART_KEY);

    },

    localStorageGetUserInfo: () => {
        const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || {};
        return { userName: userInfo.cUserName || '', clientName: userInfo.cClientName || '', division: userInfo.cDivision || '' };
    },

    localStorageSetCartInfo: (cartInfo) => {
        window.localStorage.setItem(CART_KEY, JSON.stringify(cartInfo));
    },
    localStorageRemoveCartInfo: () => {
        window.localStorage.removeItem(CART_KEY);
    },
    localStorageGeCartInfo: () => {
        const cartInfo = JSON.parse(window.localStorage.getItem(CART_KEY)) || {};
        return cartInfo;
    },
    NumFormat: (num) => {
        return new Intl.NumberFormat("ja-JP", { style: 'currency', currency: 'JPY' }).format(num);
    },
    getPosition: () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
            }, (pError) => {
                resolve({ latitude: '', longitude: '' });
            })
        })
    },

    PW_TAIL: '{[1en7',
    BASE_NAME: '',
    URL_HOME: '/',
    URL_LOGIN: '/login',
    API_BASE_URL: IS_DEV ? 'http://client-order.localhost:8082/api' : 'https://morien-paint.com/api',
    TERMBOX_SITE: 'site',
    TERMBOX_SITE_ORDER: 'site_order',
    TERMBOX_ORDER: 'order',
    TERMBOX_SITE_GOODS: 'goods',
}

export default Common
