import { useEffect, useRef, useState } from "react";
import goodsApi from "api/goodsApi";
import orderApi from "api/orderApi";
import bargainApi from "api/bargainApi";



export const useBargain = () => {
  const [termSearch, setTermSearch] = useState();
  const [bargainGoodsList, setBargainGoodsList] = useState();
  const [goods, setGoods] = useState();
  const [selectedGoods, setSelectedGoods] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const refTop = useRef();

  //Search Goods
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          txtSearch: termSearch?.txtSearch || '',
        }
        const dtResponse = await goodsApi.bargainGoodsSearch(params);
        const dtGoods = dtResponse?.data['dtGoods'] ?? [];
        setBargainGoodsList([...dtGoods]);
        if (refTop) refTop.current.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [termSearch])


  const handleSelectGoods = async (g) => {
    try {
      if (!g || g?.BargainGoodsID <= 1 || g?.GoodsID <= 1) return;
      setIsLoading(true);
      //Call Order
      const params = {
        goodsID: g.GoodsID,
        goodsSubID: g.GoodsSubID,
        bargainGoodsID: g.BargainGoodsID,

      }
      const dtResQuantity = await bargainApi.getGoodsQuantity(params);
      const price = dtResQuantity?.data['price'] || 0;
      const quantity = dtResQuantity?.data['quantity'] || 0;
      setSelectedGoods({ ...g, Quantity: quantity, Price: price });
      if (Number(quantity) !== Number(g.Quantity) || Number(price) !== Number(g.Price)) {
        const paramsSearch = {
          txtSearch: termSearch?.txtSearch || '',
        }
        //Reaload List BargainGoods
        const dtResSearch = await goodsApi.bargainGoodsSearch(paramsSearch);
        const dtGoods = dtResSearch?.data['dtGoods'] ?? [];
        setBargainGoodsList([...dtGoods]);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setGoods(null);
      setIsLoading(false);
    }
  }

  const handleOrder = async (g) => {
    try {
      if (!g || g?.GoodsID <= 1 || !g.Quantity || !g.quantityOrder || g.quantityOrder > g.Quantity) return;
      setIsLoading(true);
      //Call Order
      const paramsOrder = {
        goodsID: g.GoodsID,
        goodsSubID: g.GoodsSubID,
        bargainGoodsID: g.BargainGoodsID,
        quantity: g.quantityOrder,
        dateOrder: g.dateOrder,
        deliveryName: g.deliveryName

      }
      const dtResOrder = await orderApi.addNewBargainOrder(paramsOrder);
      const bargainGoodsID = dtResOrder?.data['bargainGoodsID'] || 0;
      const paramsSearch = {
        txtSearch: termSearch?.txtSearch || '',
      }
      //Reaload List BargainGoods
      const dtResSearch = await goodsApi.bargainGoodsSearch(paramsSearch);
      const dtGoods = dtResSearch?.data['dtGoods'] ?? [];
      setBargainGoodsList([...dtGoods]);
      if (bargainGoodsID <= 1) throw Error("エラーが発生しました");
      else alert("注文が成功しました");
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setGoods(null);
      setIsLoading(false);
    }
  }
  // Return data 
  return {
    refTop,
    setTermSearch,
    bargainGoodsList,
    goods,
    setGoods,
    selectedGoods,
    setSelectedGoods,
    handleOrder,
    handleSelectGoods,
    isLoading
  }
}