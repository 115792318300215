import axiosClient from "./axiosClient";

const bargainApi = {
    getBargainCount() {
        const url = '/bargain';
        return axiosClient.get(url, {});
    },
    getGoodsQuantity(params) {
        const url = '/bargain-goods/quality';
        return axiosClient.get(url, { params: params });
    },
}

export default bargainApi;
