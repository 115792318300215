import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import orderApi from "../../api/orderApi";
import HeaderBox from "../../components/HeaderBox";
import Loading from "../../components/Loading";
import { removeCart } from "./cartSlice";
import DeliveryInfo from "./DeliveryInfo";
import GoodsList from "./GoodsList";
import { Content, Wrapper } from "./styles";
import Common from "Common";
import * as holiday_jp from '@holiday-jp/holiday_jp';
import dayjs from "dayjs";

const dNext = new Date();
dNext.setDate(dNext.getDate() + 1);
const dNextStr = `${dNext.getFullYear()}-${(dNext.getMonth() + 1).toString().padStart(2, '0')}-${dNext.getDate().toString().padStart(2, '0')}`;
const { division } = Common.localStorageGetUserInfo();

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const [isLoading, setIsLoading] = useState(false);
  const [stepConfirm, setStepConfirm] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (stepConfirm === 3) {

      const validate = () => {
        let strMsg = "";
        //check deliveryType
        if (!cart?.siteID && !division) {
          if (!cart?.deliveryTypeID || cart?.deliveryTypeID === 2) {
            strMsg = "現場を選択してください。";
          } else if (cart.deliveryTypeID === 3) {
            strMsg = "御店を選択してください。";
          } else if (cart.deliveryTypeID === 4) {
            strMsg = "倉庫を選択してください。";
          }
        }

        if (!!cart?.deliveryDate) {
          const holidays = holiday_jp.between(new Date(cart.deliveryDate), new Date(cart.deliveryDate));
          const day = dayjs(cart.deliveryDate).get('day');
          if (holidays.length > 0) {
            strMsg = `配送希望日「${cart.deliveryDate}」は祝日（${holidays[0].name}）なので、配達が出来ません。\n他の日をご選択お願いします。`;
          }
          if (day === 0) {
            strMsg = `配送希望日「${cart.deliveryDate}」は日曜日なので、配達が出来ません。\n他の日をご選択お願いします。`;
          }
          if (cart.deliveryTypeID !== 5 && day === 6) {
            //{ id: 5, name: '引取り' },
            strMsg = `${cart.deliveryType}の配送希望日「${cart.deliveryDate}」は土曜日なので、配達が出来ません。\n他の日をご選択お願いします。`;
          }
        }
        if (strMsg) {
          alert(strMsg);
          return false;
        }
        return true;
      }

      const addNewOrder = async () => {
        try {
          setIsLoading(true);
          /**Call Api */
          const goodsList = cart.selectedList.map((item) => {
            return {
              goodsID: item.goods.GoodsID,
              goodsSubID: item.goods.GoodsSubID,
              goodsName: item.goods.GoodsName,
              goodsKana: item.goods.GoodsKana,
              goodsSize: item.goods.GoodsSize,
              colorBookID: item.goods.ColorBookID,
              colorNoID: item.goods.ColorNoID,
              colorNoName: item.goods.ColorNoName,
              quantity: item.quantity
            }
          })

          const params = {
            siteID: cart.deliveryTypeID === 5 ? '1' : cart.siteID,
            baseID: cart.deliveryTypeID === 5 ? (cart?.baseID || "1") : "1",
            deliveryTypeID: cart?.deliveryTypeID || 2,
            deliveryDate: cart?.deliveryDate || dNextStr,
            deliveryTimeID: cart?.deliveryTimeID || 1,
            orderMemo: (cart?.memo || "").trim(),
            goodsList: goodsList,
          }

          const dtResponse = await orderApi.addNewOrder(params);
          console.log(dtResponse);

          const newOrderID = dtResponse?.data['orderID'] || 0;
          if (newOrderID > 0) {
            clearData();
            alert("注文は完了しました！");
            // navigate("/goods", { replace: true });
            window.location.replace("/goods");
          }
        } catch (err) {
          alert(err);
          setStepConfirm(2);
        }
        finally {
          setIsLoading(false);
        }
      }

      const clearData = () => {
        // setOrderObj({ ...initOrder, siteID: siteID });
        dispatch(removeCart());
      }

      if (validate()) {
        addNewOrder();
      } else {
        setStepConfirm(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepConfirm, navigate]);


  return (
    <Wrapper>
      <Loading isLoading={isLoading} />
      <HeaderBox title="カート" haveCart={false} />
      <Content>
        {stepConfirm === 1 ? <GoodsList goodsList={cart?.selectedList} setNextStep={setStepConfirm} handleBack={() => { navigate(-1); }} /> : null}
        {stepConfirm === 2 ? <DeliveryInfo cart={cart} division={division} setNextStep={setStepConfirm} handleBack={() => { setStepConfirm(prevValue => (prevValue - 1)); }} /> : null}
      </Content>
    </Wrapper>
  );
};

export default Cart;
