import styled from 'styled-components';
import { Link } from "react-router-dom";


export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    @keyframes zoom {
    0% {
        transform: scale(1); /* Start at normal size */
    }
    50% {
        transform: scale(1.1); /* Scale up */
    }
    100% {
        transform: scale(1); /* Scale back to normal */
    }
}
`;

export const Header = styled.div`
    width: 80%;
    margin-top: 36px;    
    text-align: center;
    .logo{
        width: 100%;
        margin: 10px auto;
        max-width: 400px;
    }
`;

export const Body = styled.div`
    display: flex;
    width:100%;
    flex-direction: column;
    height: 74vh;
    margin-top: 8px;
    padding-bottom: 40px;
    overflow-y: auto;
    
`;

export const Button = styled(Link)`
    margin: 8px auto;
    text-decoration: none;
    color: white;
    background-color: var(--morienGreen);
    font-size: 1.5rem;
    line-height: 40px;
    font-weight: bold;
    text-align:center;
    border-radius: 5px;
    min-width: 180px;
    cursor: pointer;

    :active{
        background-color: rgba(0,149,39,0.5);
        color: black;
    }
`

export const BargainButton = styled(Link)`
    margin: 8px auto;
    text-decoration: none;
    color: white;
    background-color: #ff4c4c;
    font-size: 1.5rem;
    line-height: 60px;
    font-weight: bold;
    text-align:center;
    border-radius: 5px;
    min-width: 180px;
    cursor: pointer;
    animation: zoom 0.8s infinite;
    :active{
        transform: scale(1.1); 
        background-color: #e63939;
    }
`