import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {
    Wrapper,
    FormSearch,
    WrapperInput,
    SearchIcon,
} from './styles';
import { TextBox } from 'components/TextBox';


const SearchBox = ({ children, setTermSearch, placeholder }) => {
    const [txtSearch, setTxtSearch] = useState('');
    //handle on submit form
    const handleOnSubmit = (e) => {
        if (e.target.length > 0) e.target[0].blur();
        e.preventDefault();
        setTermSearch((preValue) => ({
            ...preValue,
            txtSearch,
        }))
    }

    //Clear text search
    const onHandleClear = () => {
        setTxtSearch("");
        setTermSearch((preValue) => ({
            ...preValue,
            txtSearch: "",
        }))
    }

    return (
        <Wrapper>
            <FormSearch onSubmit={handleOnSubmit}>
                <WrapperInput>
                    <TextBox placeholder={placeholder}
                        value={txtSearch}
                        enterKeyHint='search'
                        maxLength={100}
                        onChange={(e) => { setTxtSearch(e.target.value); }}
                        onClear={onHandleClear}
                    />
                    <SearchIcon>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </SearchIcon>
                </WrapperInput>
            </FormSearch>
            {children}
        </Wrapper>
    )
}


export default SearchBox;
