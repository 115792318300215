import React from "react";
import Common from "Common";
import {
    Button,
    Content,
    Line,
    MLabel,
    RightControl,
    Title,
    Wrapper,
    WrapperContent,
} from './styles'

const GoodsItem = ({ goods, handleSelect }) => {
    return (
        <>
            {!!goods?.Quantity && (<Wrapper >
                <WrapperContent>
                    <Content>
                        <Title>{goods.GoodsName || '-'}</Title>
                        <Line>
                            <MLabel>サイズ：</MLabel>
                            {goods.GoodsSize || '―'}
                        </Line>
                        <Line>
                            <MLabel>価格：</MLabel>
                            {Common.NumFormat(goods.Price) || '―'}
                        </Line>
                        <Line>
                            <MLabel>残：</MLabel>
                            {goods.Quantity || '0'}
                        </Line>
                    </Content>
                </WrapperContent>
                {!!goods.Quantity && (
                    <RightControl>
                        <Button onClick={() => { handleSelect(goods); }}>注文</Button>
                    </RightControl>)}
            </Wrapper>)
            }
        </>
    )
}

export default GoodsItem;