import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  BtnClose, BtnQuantity, ButtonSelect, Dialog
  , DialogContent, DialogTitle, Line, MLabel, Overlay, Quantity
  , TextContent
  , TextTile, WrapperQuantity
  , InputDate,
  Select,
  Option
} from './styles'
import { BARGAIN_DELIVERY } from "Common";

const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const dNext = new Date();
dNext.setDate(dNow.getDate() + 1);
const dNextStr = `${dNext.getFullYear()}-${(dNext.getMonth() + 1).toString().padStart(2, '0')}-${dNext.getDate().toString().padStart(2, '0')}`;

const DialogOrderBargain = ({ goods, handleClose, handleOrder }) => {
  const [quantity, setQuantity] = useState(1);
  const [dateOrder, setDateOrder] = useState(dNextStr);
  const [deliveryName, setDeliveryName] = useState(BARGAIN_DELIVERY[1]);

  const handleSelectGoods = () => {
    //select goods to cart
    const selectedGoods = {
      ...goods,
      quantityOrder: quantity,
      dateOrder,
      deliveryName
    }
    handleOrder(selectedGoods);
    handleDialogClose();
  }

  const handleDialogClose = () => {
    setQuantity(1);
    setDateOrder(dNextStr);
    setDeliveryName(BARGAIN_DELIVERY[1]);
    handleClose();
  }


  return <>
    {goods?.GoodsID && (<>
      <Dialog>
        <DialogTitle>
          <TextTile>数量選択</TextTile>
          <BtnClose onClick={() => { handleDialogClose(); }}><FontAwesomeIcon icon={faXmark} /></BtnClose>
        </DialogTitle>
        <DialogContent>
          <TextContent>
            <Line>
              <MLabel>商品：</MLabel>{goods.GoodsName || '―'}
            </Line>
            <Line>
              <MLabel>サイズ：</MLabel>{goods.GoodsSize || '-'}
            </Line>
            <Line >
              <MLabel>配達希望日：</MLabel>
              <InputDate
                min={dNowStr}
                max={'2999-12-31'}
                value={dateOrder}
                onChange={e => { setDateOrder(e.target.value) }}
              />
            </Line>
            <Line style={{ marginTop: 4 }}>
              <MLabel>配達方法：</MLabel>
              <Select
                value={deliveryName || BARGAIN_DELIVERY[1]}
                onChange={(e) => { setDeliveryName(e.target.value); }}
              >
                {BARGAIN_DELIVERY.map((item) => {
                  return (<Option key={item} value={item}>{item}</Option>)
                })}
              </Select>
            </Line>
          </TextContent>
          <WrapperQuantity>
            <BtnQuantity onClick={() => { if (quantity > 1) setQuantity(prevValue => (prevValue - 1)) }}><FontAwesomeIcon icon={faMinus} /></BtnQuantity>
            <Quantity>{quantity}</Quantity>
            <BtnQuantity onClick={() => { if (quantity < goods.Quantity) setQuantity(prevValue => (prevValue + 1)) }} ><FontAwesomeIcon icon={faPlus} /></BtnQuantity>
          </WrapperQuantity>
          {goods.Quantity > 0 && (<ButtonSelect onClick={() => { handleSelectGoods(); }} >注文</ButtonSelect>)}
          {goods.Quantity < 1 && (<div style={{ fontWeight: "bold", color: "#ff0000", textAlign: "center" }}>商品が売れた</div>)}
        </DialogContent>
      </Dialog>
      <Overlay /></>)}
  </>;
};

export default DialogOrderBargain
