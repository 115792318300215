import React from "react";
import { useBargain } from "./useBargain";
import { Content, Wrapper } from "./styles";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import SearchBox from "components/SearchBox";
import GoodsItem from "./GoodsItem";
import DialogOrderBargain from "./DialogOrderBargain";

export const Bargain = () => {
  const {
    refTop,
    setTermSearch,
    bargainGoodsList,
    goods,
    setGoods,
    selectedGoods,
    setSelectedGoods,
    handleOrder,
    handleSelectGoods,
    isLoading
  } = useBargain();

  return (
    <Wrapper>
      <Loading isLoading={isLoading} />
      <HeaderBox title="バーゲンセール商品" />
      <div style={{ position: "relative" }}>
        <SearchBox setTermSearch={setTermSearch} placeholder="商品名">
        </SearchBox>
        <Content ref={refTop}>
          {!!bargainGoodsList?.length && (
            bargainGoodsList.map((goods, index) =>
              <GoodsItem key={`${index}${(new Date()).getTime()}_${goods.GoodsID}_${goods.GoodsSubID}`}
                goods={goods}
                handleSelect={(g) => { handleSelectGoods({ ...goods }) }}
              />
            ))}
        </Content>
      </div>
      <DialogOrderBargain goods={selectedGoods} handleClose={() => { setSelectedGoods(null); setGoods(null) }} handleOrder={(g) => { handleOrder(g) }} />
    </Wrapper>);
};
