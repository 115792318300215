import axiosClient from "./axiosClient";

const orderApi = {
    addNewOrder(params) {
        const url = '/order';
        return axiosClient.post(url, params);
    },
    searchOrder(params) {
        const url = '/order';
        return axiosClient.get(url, { params: params });
    },
    searchSiteOrder(params) {
        const url = '/order_site';
        return axiosClient.get(url, { params: params });
    },
    getOrderDetails(params) {
        const url = '/order_details';
        return axiosClient.get(url, { params: params });
    },
    addNewBargainOrder(params) {
        const url = '/bargain-order';
        return axiosClient.post(url, params);
    },
}

export default orderApi;
